import React from 'react';
import styles from '../../styles/our_work/OurWorkHeader.module.css';

const OurWorkHeader = () => {
  return (
    <div id={styles.headerDiv}>
      <div id={styles.headerContent}>
        <h1 id={styles.title}>Our Work</h1>
        <h3 id={styles.headerText}>
        At Hack4Impact Boston University, we’re passionate about using technology 
        to make a difference! We partner with both national and local nonprofit 
        organizations to address their unique technical needs. Our focus is on <span>web 
        development</span>, and we’ve had the privilege of creating impactful websites and
         web applications for organizations like AmplifyLatinX and BU&apos;s very own Center for Gender,
         Sexuality, and Activism.
        </h3>
      </div>
    </div>
  );
};

export default OurWorkHeader;

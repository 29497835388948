
import Adam_Godel from "../components/assets/profile_avatars/Member Pictures/Adam_Godel.png"
import Laerk from "../components/assets/profile_avatars/Member Pictures/Ndreko, Laerk.png"
import Arnouv from "../components/assets/profile_avatars/Member Pictures/ArnouvNayana.JPG"
import Alexia from "../components/assets/profile_avatars/Member Pictures/Alexia Kim.jpg"
import Andrew from "../components/assets/profile_avatars/Member Pictures/Andrew Liu.png"
import Gerardo from "../components/assets/profile_avatars/Member Pictures/Gerardo_Vega.JPG"
import Mehmet from "../components/assets/profile_avatars/Member Pictures/Mehmet Battal.JPG"
import Nolan from "../components/assets/profile_avatars/Member Pictures/Nolan Ngim.JPG"
import Sadid from "../components/assets/profile_avatars/Member Pictures/Sadid Ethun.jpg"
import alxLogo from '../components/assets/npo_files/alxlogo.webp'

import Aditya from "../components/assets/profile_avatars/EBoard_Members/Eboard 24-25/Aditya Headshot.png"
import Isaac from "../components/assets/profile_avatars/EBoard_Members/Eboard 24-25/isaacheadshot.jpg"
import Kate from "../components/assets/profile_avatars/EBoard_Members/Eboard 24-25/kateheadshot3.JPG"
import Kwab from "../components/assets/profile_avatars/EBoard_Members/Eboard 24-25/KwabenaHeadshot.jpg"
import Linh from "../components/assets/profile_avatars/EBoard_Members/Eboard 24-25/LinhHeadshot1.jpeg"
import Rachel from "../components/assets/profile_avatars/EBoard_Members/Eboard 24-25/Rachel Pastreich Headshot.jpg"
import Saad from "../components/assets/profile_avatars/EBoard_Members/Eboard 24-25/Saad Headshot.jpeg"
import Owen from "../components/assets/profile_avatars/EBoard_Members/Eboard 24-25/Screenshot 2024-06-10 at 1.00.26 PM.png"
import Steven from "../components/assets/profile_avatars/EBoard_Members/Eboard 24-25/stevenheadshot.JPG"
import Urvi from "../components/assets/profile_avatars/EBoard_Members/Eboard 24-25/Urvi Headshot .jpg"

export const Officers = [
    {
      memberName: "Steven Tran",
      role: "President",
      pronouns: "He/him", // Add pronouns if needed
      email: "sltran@bu.edu",
      team: "Executive Board",
      src: Steven,
      desc: "Hey! I'm Steven and I'm currently the President. I'm a senior studying Computer Science. Previously I was the Vice President and Event Coordinator. During my free time I like to stay active by either working out, playing basketball or running as well as speedcubing for fun."
    },
    {
      memberName: "Aditya Chowdhri",
      role: "Vice-President",
      pronouns: "He/him", // Add pronouns if needed
      email: "aditya12@bu.edu",
      team: "Executive Board",
      src: Aditya,
      desc: "Hey guys, I'm Aditya and I'm a Junior studying Math, CS and Econ. I joined Hack4Impact as a sophomore and have absolutely loved all the different roles and faces of the organization I have gotten to see. I think our community makes us one of the best clubs on-campus and I would love to meet more of you through my coffee chats! Outside of classes I enjoy building Lego and love soccer and have over 15 shirts in my collection. If you have any questions feel free to reach out!"
    },
    {
      memberName: "Linh Bui",
      role: "Treasurer",
      pronouns: "She/her", // Add pronouns if needed
      email: "linhb@bu.edu",
      team: "Executive Board",
      src: Linh,
      desc: "Hi, I'm Linh! I am a rising sophomore majoring in Data Science and Advertising. I am currently the Treasurer for this chapter. I am an international student from Vietnam who loves photography, reading and horror movies. Feel free to reach out for a chat."
    },
    {
      memberName: "Kwabena Ampomah",
      role: "Secretary",
      pronouns: "He/him", // Add pronouns if needed
      email: "kwabamp@bu.edu",
      team: "Executive Board",
      src: Kwab,
      desc: "Hi. I'm Kwabena and this is my second year as a member of Hack4Impact. I'm currently the Director of Operation for the club. I love supporting the development of this club becaudeof our positve impact on the community and the uplifitng culture. Outside of H4I i love lifting and reviewing movies"
    },
    {
      memberName: "Owen Mariani",
      role: "Director of Impact",
      pronouns: "He/him", // Add pronouns if needed
      email: "omariani@bu.edu",
      team: "Executive Board",
      src: Owen,
      desc: "Hi, I'm Owen! I started in Hack4Impact my 1st semester freshman year and have been in the club ever since. I started as JDT Lead, then Director of Development, and now Director of Impact. I think this is the best club on campus and I would love to talk to you about it, career stuff, basketball, or the 49ers!"
    },
    {
      memberName: "Saad Naji",
      role: "Director of Development",
      pronouns: "He/him", // Add pronouns if needed
      email: "saad7@bu.edu",
      team: "Executive Board",
      src: Saad,
      desc: "Hello! I'm Saad, the Director of development at Hack4Impact. I've been coding & building for a long time and my goal is to create a space where we can all build together and learn! Besides staring at my computer, I like to play soccer, explore nature, and urban explore!"
    },
    {
      memberName: "Rachel Pastreich",
      role: "Community Director",
      pronouns: "She/her", // Add pronouns if needed
      email: "rachpast@gmail.com",
      team: "Executive Board",
      src: Rachel,
      desc: "Hello, I am Rachel and I am the director of Community at Hack4Impact. I am a sophomore majoring in computer science, pursing a BA/MS degree. I am from D.C. but I grew up in Seoul, South Korea. and a fun fact about me is that I speak Korean! Feel free to reach out for a coffee chat."
    },
    {
      memberName: "Isaac Chan",
      role: "Community Vice President",
      pronouns: "He/him", // Add pronouns if needed
      email: "isaacch@bu.edu",
      team: "Executive Board",
      src: Isaac,
      desc: "Hey, my name is Isaac! I am the Vice President of Community at Hack4Impact and just got onboarded in 2024. I am passionate about all things innovation, and creating solutions to modern day problems with the power of software. Outside of Hack4Impact, I enjoy hanging out with friends and playing volleyball!"
    },
    {
      memberName: "Urvi Sharma",
      role: "Project Sourcer",
      pronouns: "She/her",
      email: "something@bu.edu",
      team: "Executive Board",
      src: Urvi,
      desc: "Hi, I'm Urvi! I currently serve as the Project Sourcer for this chaper. Although my position is relatively new, I have been involved with Hack4Impact for a while, starting with their JDT meetings. I am junior studying data science and I grew up in Arkansas. I love to travel and have visited 10 countries so far with 2 more coming this upcoming year!"
    },
    {
      memberName: "Kate Seo",
      role: "Marketing Director",
      pronouns: "She/her", // Add pronouns if needed
      email: "kateseo@bu.edu",
      team: "Executive Board",
      src: Kate,
      desc: ""
    },

  ];


  export const teamMembers = [
    {
      memberName: "Laerk Ndreko",
      role: "Project Manager",
      pronouns: "He/him",
      email: "laerkn23@bu.edu",
      team: "ALX Portal",
      src: Laerk
    },
    {
      memberName: "Andrew Liu",
      role: "Lead Software Engineer",
      pronouns: "He/him",
      email: "andelerliutv@gmail.com",
      team: "ALX Portal",
      src: Andrew
    },
    {
      memberName: "Chanrithya “Nolan” Ngim",
      role: "Software Engineer",
      pronouns: "He/him",
      email: "nclan@bu.edu",
      team: "ALX Portal",
      src: Nolan
    },
    {
      memberName: "Gerardo Vega",
      role: "Software Engineer",
      pronouns: "He/him",
      email: "gerardov@bu.edu",
      team: "ALX Portal",
      src: Gerardo
    },
    {
      memberName: "Arnouv Nayana",
      role: "UI/UX Designer",
      pronouns: "He/him",
      email: "arnouvn@bu.edu",
      team: "ALX Portal",
      src: Arnouv
    },
  ];
  

  export const CGA = [
    {
        memberName: "Alexia Kim",
        role: "Product Manager & UI/UX Designer",
        pronouns: "She/her",
        email: "alexiak@bu.edu",
        team: "CGSA Website",
        src: Alexia
      },
      {
        memberName: "Adam Godel",
        role: "Lead Software Engineer",
        pronouns: "He/him",
        email: "agodel@bu.edu",
        team: "CGSA Website",
        src: Adam_Godel
      },
      {
        memberName: "Mehmet Battal",
        role: "Software Engineer",
        pronouns: "He/him",
        email: "zbattal@bu.edu",
        team: "CGSA Website",
        src: Mehmet
      },
      {
        memberName: "Sadid Ethun",
        role: "Software Engineer",
        pronouns: "He/him",
        email: "sethun@bu.edu",
        team: "CGSA Website",
        src: Sadid
      }
  ]

export const ALX = [
  {
    projectName: "ALX Intranet",
    date: "Fall 2024",
    info: "AmplifyLatinX (ALX) is dedicated to empowering Latinx professionals through community engagement and resources. The ALX Membership Portal project aims to create a dynamic web application that enhances member experience by offering personalized profiles, exclusive discounts, job postings, and access to community events. This platform will serve as a central hub, providing v aluable benefits and fostering connections among members, ultimately enriching their professional journeys.",
    src: alxLogo
  
  }
]
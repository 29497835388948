import React from 'react';
import { PastProjectCard } from '../our_work/PastProjectCard';
import styles from '../../styles/our_work/PastProjects.module.css';

// Sample project data
const SAMPLE_PROJECTS = [
  {
    path: 'project-1',
    title: 'Foster Friends',
    season: 'Spring',
    year: '2021',
    image: 'https://plugins.jetbrains.com/files/16260/113019/icon/pluginIcon.png',
  },
  {
    path: 'project-2',
    title: 'Campus Clubs',
    season: 'Fall',
    year: '2020',
    image: 'https://plugins.jetbrains.com/files/16260/113019/icon/pluginIcon.png',
  },
  {
    path: 'project-3',
    title: 'AgriWorks',
    season: 'Spring',
    year: '2021',
    image: 'https://plugins.jetbrains.com/files/16260/113019/icon/pluginIcon.png',
  },
  {
    path: 'project-4',
    title: 'Caties Closet',
    season: 'Spring',
    year: '2019',
    image: 'https://plugins.jetbrains.com/files/16260/113019/icon/pluginIcon.png',
  },
];

const PastProject: React.FC = () => {
  return (
    <div className={styles.pastProjectsContainer}>
      <h2 id={styles.sectionTitle}>Past Projects</h2>
      <div id={styles.projectsDisplay}>
        {SAMPLE_PROJECTS.map((project, index) => (
          <PastProjectCard
            key={index}
            link={`ourwork/${project.path}`}
            title={project.title}
            date={`${project.season} ${project.year}`}
            image={project.image}
            altText={project.title}
          />
        ))}
      </div>
    </div>
  );
};

export default PastProject;

import React, { useState } from 'react';
import styles from '../styles/contact/Contact.module.css';
import MessageSent from './MessageSent';

function ContactPage() {
    const [sent, setSent] = useState(false);
    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        subject: '',
        email: '',
        phone: '',
        text: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const validateForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { fname, lname, subject, email, phone, text } = formData;

        const verifyEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        const verifyPhoneNumber = /^(?:\d{10}|\d{3} \d{3} \d{4}|\d{3}-\d{3}-\d{4})$/;

        if (!fname || !lname || !subject || !email || !phone || !text) {
            alert('All fields are required.');
            return false;
        }

        if (!verifyEmail.test(email)) {
            alert('Invalid email format.');
            return false;
        }

        if (!verifyPhoneNumber.test(phone)) {
            alert('Invalid phone number format. Use 0000000000, 000 000 0000, or 000-000-0000.');
            return false;
        }

        setSent(true);
        return true;
    };

    return sent ? (
        <MessageSent />
    ) : (
        <div id={styles.headerDiv}>
            <div id={styles.headerContent}>
                <h1 id={styles.title}>Contact Us</h1>
                <form
                    action="https://formspree.io/f/xldewzrd"
                    method="POST"
                >
                    <div>
                        <label>First Name</label>
                        <input
                            type="text"
                            name="fname"
                            value={formData.fname}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label>Last Name</label>
                        <input
                            type="text"
                            name="lname"
                            value={formData.lname}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <p>
                        <label>Subject</label>
                        <input
                            type="text"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                        />
                    </p>
                    <p>
                        <label>Email Address</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </p>
                    <p>
                        <label>Phone Number</label>
                        <input
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                    </p>
                    <p>
                        <label>Your message</label>
                        <textarea
                            name="text"
                            value={formData.text}
                            onChange={handleChange}
                            required
                        ></textarea>
                    </p>
                    <div className={styles.buttonHolder}>
                        <button type="submit">Send Message</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ContactPage;

import React from 'react';
import OurWorkHeader from '../components/our_work/OurWorkHeader';
import PastProjects from '../components/our_work/PastProjects';
import Projects from '../components/projects/Projects';
import CurrentProjects from '../components/projects/CurrentProjects';
import PastProject from '../components/projects/PastProjects';
import styles from '../styles/our_work/OurWork.module.css';
import alxLogo from '../components/assets/npo_files/alxlogo.webp'
import cgsa from "../components/assets/npo_files/bucgsa.jpg"
import logoBorder from '../components/assets/ValueCardBackgrounds/ValueCardBackground1.svg'
import Person from '../components/Person';

import { teamMembers } from '../data/data';
import { CGA } from '../data/data';
import { ALX } from '../data/data';
import styles1 from '../styles/about_us/AboutUs.module.css';


import githubIcon from '../components/assets/icons/github_icon.png';
import internetIcon from '../components/assets/icons/internet_icon.png';


const OurWork: React.FC = () => {
  return (
    <div id={styles.OurWorkPage}>
      <div className={styles.content}>
        <OurWorkHeader />
        <CurrentProjects />
        <PastProject />
        {/* <div className={styles.currentProjects}>
          <div className={styles.container}>
            <h1 className={styles.headTitle}>Current Projects</h1>
          </div>
         */}
          {/* <Projects isFeatured = {false}/> */}
          {/* <ProjectInfo /> */}
          {/* <ALXTeam />
          <ProjectInfo2 />
          <CGSATeam /> */}
        {/* </div> */}
        {/* <div className={styles.pastProjects}>
          <PastProjects />
        </div> */}
      </div>
    </div>
  );
};


function ALXTeam() {
  return (
    <div className={styles1.teamMembersDiv}>      
      <h2>Team Members</h2>
      <div className={styles1.teamMembersPhotos}>
        {teamMembers.map((member: { memberName: any; role: any; pronouns: any; src?: any; }, index: any) => (
          <Person
            key={index} // Ensure you provide a unique key for each component
            memberName={member.memberName}
            role={member.role}
            pronouns={member.pronouns}
            src={member.src}
          />))}
      </div>
    </div>
  )
}

function CGSATeam() {
  return (
    <div className={styles1.teamMembersDiv}>      
      <h2>Team Members</h2>
      <div className={styles1.teamMembersPhotos}>
        {CGA.map((member: { memberName: any; role: any; pronouns: any; src?: any; }, index: any) => (
          <Person
            key={index} // Ensure you provide a unique key for each component
            memberName={member.memberName}
            role={member.role}
            pronouns={member.pronouns}
            src={member.src}
          />))}
      </div>
    </div>
  )
}

function ProjectInfo() {
  return (
    <div className={styles.cont}>

      <h1>ALX Intranet</h1>

      <div className={styles.innerCont}>
        <div className={styles.imageCont}>
          <img className={styles.border} src={logoBorder}/>
          <img className={styles.projImage} src={alxLogo}/>
        </div>

        <div className={styles.info}>
          <h2>Fall 2024</h2>
          <p>AmplifyLatinX (ALX) is dedicated to 
            empowering Latinx professionals through community 
            engagement and resources. The ALX Membership Portal project 
            aims to create a dynamic web application that enhances member
             experience by offering personalized profiles, exclusive 
             discounts, job postings, and access to community events. 
             This platform will serve as a central hub, providing v
             aluable benefits and fostering connections among members, 
             ultimately enriching their professional journeys.
          </p>
        </div>
      </div>

    </div>

  )
}

function ProjectInfo2() {
  return (
    <div className={styles.cont}>

      <h1>CGSA Website</h1>

      <div className={styles.innerCont}>
        <div className={styles.imageCont}>
          <img className={styles.border} src={logoBorder}/>
          <img className={styles.projImage} src={cgsa}/>
        </div>

        <div className={styles.info}>
          <h2>Fall 2024</h2>
          <p>The Center for Gender, Sexuality & Activism (CGSA) at Boston
             University aims to foster an inclusive advocacy space. The
              CGSA Website project will revamp the site, moving to a new
               domain (bucgsa.org) while enhancing functionality and design.
                Featuring a fun, approachable look, the updated site will
                 include easy navigation, an interactive calendar, and
                  sections for community involvement and blog posts,
                   broadening CGSA&aposs reach as a welcoming resource for all.
          </p>
        </div>
      </div>

    </div>

  )
}



export default OurWork;

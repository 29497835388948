import React, { useState } from 'react';
import styles from '../styles/people/Person.module.css';
import globe from './assets/h4i_files/globe.svg';
import default_pfp from "../components/assets/icons/default_pfp.png"

/*
 * Person component
 * Props:
 * src: path to image. default to globe if not given.
 * memberName, team, role, pronouns: strings for each field.
 */
function Person(props: any) {

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  let imageSrc: string;
  if (props.src == undefined || props.src == null) {
    imageSrc = default_pfp;
  } else {
    imageSrc = props.src;
  }

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <div>
      <div className={styles.Person} onClick={handleOpenDialog}>
        <img src={imageSrc} />
        <h2>{props.memberName}</h2>
        <p className={styles.team}>{props.team}</p>
        <p>{props.role}</p>
        <p className={styles.Pronouns}>{props.pronouns}</p>
      </div>
      {isDialogOpen && (
        <div className={styles.modalOverlay} onClick={handleCloseDialog}>
          <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
            <button className={styles.closeButton} onClick={handleCloseDialog}>x</button>
            <h2>{props.memberName}</h2>
            <p><span>Team</span>: {props.team}</p>
            <p><span>Role</span>: {props.role}</p>
            <p><span>Pronouns</span>: {props.pronouns}</p>
            <p>{props.desc}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Person;

import React, { useEffect, useRef, useState } from 'react';
import styles from '../styles/about_us/AboutUs.module.css';
import Person from '../components/Person';
import ValueCard from '../components/about_us/ValueCard';

import headerDesktop from '../components/assets/backgrounds/about_us/group.png';
import headerMobile from '../components/assets/backgrounds/about_us/aboutus_header_mobile2023.png';
import placeholder from '../components/assets/placeholder.png';
import { useAxios } from '../components/HelperFunctions';
import globe from '../components/assets/globe.svg';

import { Officers } from '../data/data';
import { teamMembers } from '../data/data';
import { CGA } from '../data/data';



function AboutUs() {
  return (
    <div className={styles.aboutUs}>
      <AboutUsHeader />
      <OurMission />
      <ValueCardRow />
      <ExecBoard />
      <TeamMembers />
    </div>
  );
}

function AboutUsHeader() {
  return (
    <div className={styles.headerDiv}>
      <picture>
        {/* background image should change when navbar changes */}
        <source srcSet={headerDesktop} media={'(min-width: 700px)'} />
        <img src={headerMobile} className={styles.headerImg}></img>
      </picture>
      <div className={styles.headerText}>
        <h1>About Us</h1>
      </div>
    </div>
  );
}

function OurMission() {
  return (
    <div className={styles.ourMission}>
      <div className={styles.divider}/>
      <h2>Our Mission</h2>
      <h3>
      Our mission is to develop software for 
      other <a>nonprofits</a> to meet their social and humanitarian needs. 
      This partnership reaps mutual benefit, granting ambitious software 
      engineers valuable experience while providing nonprofits with a 
      high-quality product free of charge.
      </h3>
      <div className={styles.divider}/>
    </div>
  );
}

function ValueCardRow() {
  const beOpenMinded =
    'Our process depends on openness to different people, topics, and perspectives. We embrace difference and work against intolerance to foster an inclusive environment. Our goal is to expose our members to the vast opportunities and daunting challenges in our work.';

  const goBeyondTechnology =
    'Technology is only one tool we use in our greater mission for social impact. Technology alone is not enough. We learn from, work with, and are inspired by others who are tackling social problems using a multitude of tools.';

  const developWithCare =
    'We build with others in mind. Empathy and compassion are crucial to serving our partner organizations and members. When we embark on projects, we work to deeply understand the people who we are helping.';

  return (
    <div className={styles.valuesCardsDiv}>
      <div className={styles.h2Cont}><h2>Our Values</h2></div>
      <div className={styles.valuesCards}>
        <ValueCard mainText={'Beyond Technology'} hoverText={goBeyondTechnology} />
        <ValueCard mainText={'Develop with Care'} hoverText={developWithCare} revBackground={true} />
        <ValueCard mainText={'Open Mindedness'} hoverText={beOpenMinded} />
      </div>
    </div>
  );
}

function ExecBoard() {
  const res = useAxios(
    process.env.REACT_APP_ROOT_URL +
      '/api/members?populate=avatar,componentRolesArr&filters[memberDisplayStatus][$eq]=Current Board Member',
    'GET',
    {},
  );
  const boardMembers = res.data ? res.data['data'] : [];
  const execOrder = [
    'Executive Director',
    'Director of Product',
    'Director of Engineering',
    'Director of Design',
    'Director of Education',
    'Director of Finance',
    'Director of Events',
    'Director of Recruitment',
    'Director of Sourcing',
    'Senior Advisor',
  ];

  return (
    <div className={styles.execBoardDiv}>
      <div className={styles.h2Contain}>
        <h2>Executive Board</h2>
        <div className={styles.divider}/>
      </div>
      
      <div className={styles.execBoardPhotos}>

        {Officers.map((member: {
          team: any; memberName: any; role: any; desc: any; pronouns: any; src?: any; 
}, index: any) => (
        <Person
          key={index} // Ensure you provide a unique key for each component
          memberName={member.memberName}
          role={member.role}
          pronouns={member.pronouns}
          src={member.src}
          team={member.team}
          desc={member.desc}
        />))}
        {/* {!boardMembers
          ? boardMembers
          : boardMembers
              .sort(
                (a, b) =>
                  execOrder.indexOf(
                    (a['attributes']['componentRolesArr'] as Array<any>).find((e) => e['isDisplayRole'] == true)[
                      'title'
                    ],
                  ) -
                  execOrder.indexOf(
                    (b['attributes']['componentRolesArr'] as Array<any>).find((e) => e['isDisplayRole'] == true)[
                      'title'
                    ],
                  ),
              )
              .map((item, index) => (
                <Person
                  key={index}
                  memberName={item['attributes']['firstName'] + ' ' + item['attributes']['lastName']}
                  role={
                    (item['attributes']['componentRolesArr'] as Array<any>).find((e) => e['isDisplayRole'] == true)[
                      'title'
                    ]
                  }
                  pronouns={item['attributes']['pronouns']}
                  src={
                    item['attributes']['avatar']['data']
                      ? item['attributes']['avatar']['data']['attributes']['url']
                      : null
                  }
                />
              ))} */}

      </div>
    </div>
  );
}

function TeamMembers() {
  // fetch data with axios, assign the array of members to members var
  // const res = useAxios(process.env.REACT_APP_ROOT_URL + "/api/members?populate=*", "GET", {});
  const res = useAxios(
    process.env.REACT_APP_ROOT_URL +
      '/api/members?pagination[page]=1&pagination[pageSize]=100&populate=avatar,componentRolesArr&filters[memberDisplayStatus][$eq]=Current Member',
    'GET',
    {},
  );
  const members = res.data ? res.data['data'] : [];

  // members.map(item => console.log(item["attributes"]["firstName"]));

  return (


    <div className={styles.teamMembersDiv}>
      <div className={styles.h2Contain}>
        <h2>Team Members</h2>
        <div className={styles.divider}/>
        </div>
{/* 
      <div className={styles.teamMembersPhotos}>
      <Person 
        memberName={"Owen Mariani"}
        role={"Director of Impact"}
        pronouns={"He/Him"}
        src={null}
        />
      </div> */}

      <h2>Amplify LatinX Membership Portal</h2>
      
      <div className={styles.teamMembersPhotos}>
        {teamMembers.map((member: {
          team: any; memberName: any; role: any; pronouns: any; src?: any; 
}, index: any) => (
          <Person
            key={index} // Ensure you provide a unique key for each component
            memberName={member.memberName}
            role={member.role}
            pronouns={member.pronouns}
            src={member.src}
            team={member.team}
          />))}
      </div>

      <h2>BU Center for Gender, Sexuality, and Activism Website</h2>

      <div className={styles.teamMembersPhotos}>
        {CGA.map((member: {
          team: any; memberName: any; role: any; pronouns: any; src?: any; 
}, index: any) => (
          <Person
            key={index} // Ensure you provide a unique key for each component
            memberName={member.memberName}
            role={member.role}
            pronouns={member.pronouns}
            src={member.src}
            team={member.team}
          />))}
      </div>

        {/* {!members
          ? members
          : // render team members
            members.map((item, index) => (
              <Person
                key={index}
                memberName={item['attributes']['firstName'] + ' ' + item['attributes']['lastName']}
                team={
                  (item['attributes']['componentRolesArr'] as Array<any>).find((e) => e['isDisplayRole'] == true)[
                    'team'
                  ]
                }
                role={
                  (item['attributes']['componentRolesArr'] as Array<any>).find((e) => e['isDisplayRole'] == true)[
                    'title'
                  ]
                }
                pronouns={item['attributes']['pronouns']}
                src={
                  item['attributes']['avatar']['data']
                    ? item['attributes']['avatar']['data']['attributes']['url']
                    : null
                }
              />
            ))} */}
      </div>
  );
}

export default AboutUs;

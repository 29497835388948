import React from 'react';
import FeaturedProjectCard from './FeaturedProjectCard';
import styles from '../../styles/projects/Projects.module.css';
import { useAxios, getSeason } from '../HelperFunctions';
import StandardButton from '../buttons/StandardButton';

const CurrentProjects = (props: any) => {
  let res = null;
  let projects = null;

  //check which type of projects were rendering
  if (props.isFeatured == true) {
    // fetch featuredProjects from backend
    res = useAxios(
      process.env.REACT_APP_ROOT_URL + '/api/projects?populate=*&filters[isFeatured][$eq]=true',
      'GET',
      {},
    );
    projects = res.data ? res.data['data'] : [];
  } else {
    //fetch current projects
    res = useAxios(
      process.env.REACT_APP_ROOT_URL + '/api/projects?populate=*&filters[isCurrentProject][$eq]=true',
      'GET',
      {},
    );
    projects = res.data ? res.data['data'] : [];
  }
  // fetch featuredProjects from backend

  const featuredProjects = [
    {
      link: "alx",
      title: "Amplify LatinX",
      date: "Fall 2024",
      summary: "The 'ALX Membership Portal' is an external web application offering exclusive benefits and premium content for Amplify LatinX members. Features include personalized profiles, membership status tracking, benefits and discounts, event access, community engagement, and efficient membership management.",
      image: 'https://plugins.jetbrains.com/files/16260/113019/icon/pluginIcon.png',
      altText: "ALX Membership Portal"
    },
    // Add more project objects here
    {
      link: "cgsa",
      title: "CGSA Website",
      date: "Fall 2024",
      summary: "Hack4Impact is developing a redesigned website for the Center for Gender, Sexuality, and Activism to boost community engagement and enhance the center’s online presence. The site will clearly define the center’s dual role as a community and event space, featuring a welcoming and professional design to elevate the CGSA brand.",
      image: 'https://plugins.jetbrains.com/files/16260/113019/icon/pluginIcon.png',
      altText: "alt text for project 2"
    }
  ];

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Current Projects</h1>
      <div className={styles.featuredProjectCards}>
        {/* if display current projects, show current projects title
        {props.isFeatured ? null : <h2 id={styles.sectionTitle}>Current Projects</h2>}
        {!projects
          ? projects
          : projects.map((item, index) => {
              const startDate = item['attributes']['startDate']
                ? getSeason((item['attributes']['startDate'] as string).substring(5, 7) as unknown as number) +
                  ' ' +
                  (item['attributes']['startDate'] as string).substring(0, 4)
                : '';
              const endDate =
                item['attributes']['Season'] && item['attributes']['Year']
                  ? ' - ' + item['attributes']['Season'] + ' ' + item['attributes']['Year']
                  : '';
              const fullDate = startDate + endDate;
              return (
                <FeaturedProjectCard
                  key={index}
                  link={'ourwork/' + item['attributes']['path']}
                  title={item['attributes']['title']}
                  date={fullDate}
                  summary={item['attributes']['summary']}
                  image={
                    item['attributes']['image']['data']
                      ? item['attributes']['image']['data'][0]['attributes']['url']
                      : 'https://plugins.jetbrains.com/files/16260/113019/icon/pluginIcon.png'
                  }
                  altText={item['attributes']['imageAltText']}
                />
              );
            })} */}
        {featuredProjects.map((project, index) => (
        <FeaturedProjectCard
          key={index} // Ensure you provide a unique key for each card
          link={project.link}
          title={project.title}
          date={project.date}
          summary={project.summary}
          image={project.image}
          altText={project.altText}
        />
      ))}


      </div>
      {/**display see more button if showing featured projects */}
    </div>
  );
};

const NoProjects = () => {
  return <h1>Oops! There are currently no projects loaded. Try again later.</h1>;
};

export default CurrentProjects;
